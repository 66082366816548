<template>
  <div>
    <app-main>
      <template v-slot:main-content>
        <div class="invite-friends">
          <div class="invite-title">邀请好友注册，立享佣金奖励</div>
          <div class="invite-link">{{ language }}，{{ url }}</div>
          <div class="invite-btn">
            <el-button type="primary" size="small" v-if="url !== ''" v-clipboard:copy="url" v-clipboard:success="onCopy" v-clipboard:error="onError">复制网址，邀请好友注册</el-button>
            <el-button type="primary" size="mini" @click="change">更换邀请语</el-button>
          </div>
          <div class="invite-total" v-if="people != ''">
            已成功邀请 <span>{{ people }}人</span>
          </div>
          <!-- <button @click="s">下载</button> -->
          <div class="invite-step">
            <div class="invite_step_item one">
              <img src="~assets/images/mall/people1.png" />
              <span>①分享链接给好友</span>
            </div>
            <div class="jiantou"></div>
            <div class="invite_step_item two">
              <img src="~assets/images/mall/people2.png" />
              <span>②好友注册，并下单成功</span>
            </div>
            <div class="jiantou"></div>
            <div class="invite_step_item three">
              <img src="~assets/images/mall/people3.png" />
              <span>③您将获得佣金奖励</span>
            </div>
            <span class="computed">{{ $store.getters.getSiteInfo.comm }}</span>
          </div>
        </div>
        <el-card class="box-card" v-if="imageList">
          <div class="invite-title">保存海报, 邀请好友</div>
          <div class="img_div" v-for="item in imageList" :key="item">
            <keep-alive> <img :src="item" style="padding-left: 16px; border-radius: 4px; width: 200px; height: 300px" /></keep-alive>
            <a href="#"
              ><div class="mask" @click="downloadCodeImg(item)"><h3>保存图片</h3></div></a
            >
          </div>
        </el-card>
        <div class="invitetable_wrapper">
          <div class="menu">
            <el-input v-model="mobile" placeholder="手机号"></el-input>
            <el-button @click="clickQuery" type="primary">查询</el-button>
            <el-button @click="clickReset">重置</el-button>
          </div>
          <div class="table">
            <el-table :data="friends.slice((currentPage - 1) * pageSize, currentPage * pageSize)" :header-cell-style="{ background: '#fafafa', color: 'rgba(0,0,0,.85)', fontWeight: '500' }">
              <el-table-column label="序号">
                <template slot-scope="scope"
                  ><span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span></template
                >
              </el-table-column>
              <el-table-column prop="user_id" label="用户邀请ID"></el-table-column>
              <el-table-column prop="username" label="用户名"></el-table-column>
              <el-table-column label="推广码"></el-table-column>
              <el-table-column prop="user_type" label="用户类型"></el-table-column>
              <el-table-column prop="createtime" label="创建时间"></el-table-column>
              <el-table-column prop="updatetime" label="修改时间"></el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="pagination">
              <el-pagination background @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next" :total="friends.length"> </el-pagination>
            </div>
          </div>
        </div>
      </template>
    </app-main>
  </div>
</template>
<script>
import AppMain from 'components/baseStructure/AppMain'
import { inviteHttp, selectAll, inviteSelect, postInvite } from 'network/api'
// import DataStatistics from '../../orderAdmin/orderList/childComps/DataStatistics.vue'
// import { GetUrlParam } from '@/utils/GetUrlParams.js'
export default {
  inject: ['mainReload'],
  components: {
    AppMain
    // DataStatistics
  },
  data() {
    return {
      url: '',
      mobile: '',
      friends: [],
      currentPage: 1,
      pageSize: 15,
      imageList: [],
      dialogVisible: false,
      imageurl: '',
      arr: [],
      language: '我发现一个电商人必备的礼品代发网站',
      people: ''
    }
  },
  created() {
    // 初始化全部邀请信息
    this.getSelectAll()
    // 邀请图片
    postInvite().then((res) => {
      if (res.code !== 1) {
        return
      }
      this.imageList = res.data
      this.imageurl = res.data[0]
    })
    // eslint-disable-next-line camelcase
    const frontend_url = this.$store.getters.getSiteInfo.frontend_url || []
    // 获取邀请ID
    inviteHttp().then((res) => {
      // eslint-disable-next-line camelcase
      this.url = frontend_url + '/register?user_id=' + res.data
    })
  },
  methods: {
    randomNo(j) {
      var randomno = ''
      for (var i = 0; i < j; i++) {
        randomno += Math.floor(Math.random() * 10)
      }
      randomno = new Date().getTime() + randomno
      return randomno
    },
    downloadCodeImg(item) {
      this.downloadFile(item, this.randomNo(6))
    },
    downloadFile(content, fileName) {
      var base64ToBlob = function (code) {
        const parts = code.split(';base64,')
        const contentType = parts[0].split(':')[1]
        const raw = window.atob(parts[1])
        const rawLength = raw.length
        const uInt8Array = new Uint8Array(rawLength)
        for (let i = 0; i < rawLength; ++i) {
          uInt8Array[i] = raw.charCodeAt(i)
        }
        return new Blob([uInt8Array], {
          type: contentType
        })
      }
      const aLink = document.createElement('a')
      const blob = base64ToBlob(content) // new Blob([content]);
      const evt = document.createEvent('HTMLEvents')
      evt.initEvent('click', true, true) // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName
      aLink.href = URL.createObjectURL(blob)
      aLink.click()
    },
    // -----以上是保存图片----------
    // 更换邀请语
    change() {
      this.arr = this.$store.getters.getSiteInfo.invite_title
      const index = Math.floor(Math.random() * this.arr.length)
      this.language = this.arr[index]
    },
    // 分页改变
    handleCurrentChange(val) {
      this.currentPage = val
    },
    // 邀请的全部用户信息请求
    getSelectAll() {
      selectAll().then((res) => {
        if (res.code !== 1) {
          return
        }
        this.friends = res.data
        if (res.data === null) {
          this.people = '0'
        } else {
          this.people = res.data.length
        }
      })
    },
    // 邀请用户信息筛选请求
    getInviteSelect() {
      inviteSelect({
        mobile: this.mobile
      }).then((res) => {
        if (res.code === 1) {
          if (Array.isArray(res.data)) {
            this.friends = res.data
          } else {
            this.friends = []
            this.friends.push(res.data)
          }
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 复制成功时的回调函数
    onCopy() {
      this.$message.success('复制成功')
    },
    // 复制失败时的回调函数
    onError() {
      this.$message.error('复制失败')
    },
    // 查询
    clickQuery() {
      this.getInviteSelect()
    },
    // 重置
    clickReset() {
      this.mainReload()
    }
  }
  // beforeMount () {
  //   this.isShare = GetUrlParam('share')
  //   console.log('我的share', this.isShare)
  // }
}
</script>
<style scoped src="styles/views/account/inviteFriends.css"></style>>
<style lang="scss" scoped>
element.style {
  width: 40%;
}
.menu .el-input__inner {
  width: 240px;
  margin-right: 12px;
}
.box-card {
  padding-bottom: 30px;
  font-family: 'Microsoft YaHei';
}
.img_div {
  height: 300px;
  display: flex;
  position: relative;
  float: left;
  border-radius: 4px;
}
</style>
